@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.App {
  text-align: center;
}


.-cbwsdk-connect-content-header{
  width: 300px !important;
  margin: auto !important;
}

.-cbwsdk-connect-content-layout {
  width: 300px !important;
  display: flex !important;
  flex-direction: column !important;
}




.-cbwsdk-connect-content-column-left {
  display: none !important;
}


.-cbwsdk-connect-content-layout {
  position: relative !important;
}

.-cbwsdk-connect-content-column-right {
  position: absolute !important;
  top: 24px !important;
  left: 10px !important;
  box-shadow: 0px 1px 5px 0px #00000040;
}

.-cbwsdk-wallet-steps {
  position: absolute !important;
  bottom: -190px !important;
  text-align: center !important;
  left: 40px !important;
  margin-top: 4px !important;
}

.-cbwsdk-css-reset .-cbwsdk-try-extension {
  display: none !important;
}

.-cbwsdk-css-reset .-cbwsdk-connect-content {
  width: 100vw !important;
  border-radius: 16px 16px 0 0 !important;
  height: 560px !important;
}

.-cbwsdk-cancel-button::before {
  content: "Scan to connect";
  display: block;
  font-weight: 500;
  line-height: 28px;
  font-size: 22px;
  margin-top: 14px;
}

.-cbwsdk-cancel-button::after {
  content: url('/src/resources/images/FullLogo.png');
  position: absolute;
  top: 12px;
}

.-cbwsdk-cancel-button {
  width: 100% !important;
  display: flex !important;
  justify-content: center;
  margin-top: 12px !important;
}

.-cbwsdk-cancel-button svg {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
}

.-cbwsdk-connect-content-heading {
  display: none;
  position: absolute;
}

.-cbwsdk-connect-content-qr-wrapper {
  position: relative !important;

}

.-cbwsdk-connect-content-qr-wrapper img {
  width: 80vw;
  position: absolute;
  height: 80vw;
  top: 0;
  left: 0;


}

.-cbwsdk-connect-dialog-box {
  position: absolute !important;
  bottom: 0 !important;
  height: 560px !important;
}
